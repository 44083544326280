<template>
  <base-dialog
    title="Información anexa para el usuario"
    btn_text="Editar"
    :icon="null"
    ref="dialog"
    @click_open="open"
    width="800"
    fullscreen
  >
    <v-stepper
      alt-labels
      v-model="step"
      style="background: transparent"
      slot="outside"
    >
      <v-stepper-header>
        <div class="my-auto pb-10">
          <v-btn @click="step--" :disabled="step == 1" elevation="0" icon large>
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </div>
        <v-spacer />
        <draggable v-model="headers" class="draggable">
          <div class="d-inline-block" v-for="(h, i) in headers" :key="i">
            <v-stepper-step
              :step="1 + i"
              @click="step = i + 1"
              class="pointer"
              ghost-class="ghost"
            >
              <div class="mt-0">
                <v-text-field
                  v-model="h.title"
                  hide-details
                  class="pt-0"
                  style="margin: auto; max-width: 80%"
                ></v-text-field>
                <div class="close">
                  <v-btn
                    elevation="0"
                    height="30"
                    class="mt-2 mr-4"
                    @click="del(i)"
                    icon
                    small
                    v-if="i != 0"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
              </div>
            </v-stepper-step>
          </div>
        </draggable>

        <v-spacer />
        <div class="my-auto pb-10 pl-5">
          <v-btn icon x-large @click="add()">
            <v-icon size="36">mdi-plus-circle</v-icon>
          </v-btn>
          <div>Añadir</div>
        </div>
        <div class="my-auto pb-10">
          <v-btn
            @click="step++"
            :disabled="step == headers.length"
            elevation="0"
            icon
            large
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </v-stepper-header>
    </v-stepper>
    <v-row>
      <v-col>
        <editor
          v-if="editor"
          :api-key="apiKey"
          v-model="headers[step - 1].text"
          :init="init"
        />
      </v-col>
    </v-row>
    <template slot="actions">
      <v-spacer></v-spacer>
      <v-btn
        outlined
        @click="$refs.dialog.close()"
        style="height: 25px; width: 100px"
        elevation="0"
      >
        {{ $t("cancel") }}
      </v-btn>
      <v-btn
        @click="save"
        style="
          width: 100px !important;
          color: #363533;
          height: 25px;
          padding-top: 10px !important;
        "
        elevation="0"
      >
        <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
        {{ $t("save", { name: $t("") }) }}
      </v-btn>
    </template>
  </base-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "EditConsentFormService",
  props: ["service"],

  watch: {
    "headers.length": {
      handler() {
        if (this.dialog) {
          this.showEditor();
        }
      },
      deep: true,
    },
    step: {
      handler() {
        if (this.dialog) {
          this.showEditor();
        }
      },
      deep: true,
    },
  },
  components: {
    draggable: () => import("vuedraggable"),
    Editor: () => import("@tinymce/tinymce-vue"),
  },
  data() {
    return {
      headers: [],
      step: 1,
      editor: false,
      init: {
        height: 200,
        content_style: "* {color: white}",
        menubar: false,
        selector: "#instance3",
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
        ],
        toolbar:
          "bold italic underline " +
          "undo redo | fontsizeselect formatselect| forecolor backcolor |" +
          "alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | ",
      },
    };
  },
  methods: {
    open() {
      if (this.service.consent_form) this.headers = this.service.consent_form;
      else this.headers = [{ title: "Información personal", text: null }];
      this.dialog = true;
      this.showEditor();
    },

    showEditor() {
      this.editor = false;
      setTimeout(() => {
        this.editor = true;
        console.log("EDITOR");
      }, 100);
    },
    add() {
      this.headers.push({ title: null, text: null });
    },
    del(index) {
      this.headers.splice(index, 1);
      while (this.step > this.headers.length) {
        console.log(this.step, this.headers.length);
        this.step--;
      }
    },
    ...mapActions("services", ["consentFormService"]),
    save() {
      this.consentFormService({
        id: this.service.id,
        consent_forms: this.headers,
      }).then((response) => {
        this.$refs.dialog.close();
        this.$emit("update");
      });
    },
  },
  computed: {
    apiKey() {
      return process.env.VUE_TINYMCE_API_KEY;
    },
  },
};
</script>

<style lang="sass">
.draggable
  max-width: 77%
  overflow-x: auto
  white-space: nowrap
  .v-stepper__step
    padding: 16px
</style>
